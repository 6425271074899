import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { lazy, Suspense } from "react";

import "./App.css";

const Navigation = lazy(() => import("./components/navigation/Navigation"));
const Home = lazy(() => import("./pages/home/Home"));
const About = lazy(() => import("./pages/about/About"));
const Contact = lazy(() => import("./pages/contact/Contact"));
const Products = lazy(() => import("./pages/products/Products"));
const ProductsOverview = lazy(() =>
  import("./pages/products-overview/ProductsOverview")
);
const ProductsCategory = lazy(() =>
  import("./pages/products-overview/ProductsCategory")
);
const Footer = lazy(() => import("./pages/footer/Footer"));
const CookieConsent = lazy(() =>
  import("./components/cookie-consent/CookieConsent")
);

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <div className="app-container">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Navigation />}>
                <Route index element={<Home />} />
                <Route path="chi-siamo" element={<About />} />
                <Route path="contatti" element={<Contact />} />
                <Route
                  path="panoramica-prodotti/*"
                  element={<ProductsOverview />}
                />
                <Route
                  path="panoramica-prodotti/:category"
                  element={<ProductsCategory />}
                />
                <Route path="catalogo-prodotti" element={<Products />} />
                <Route path="*" element={<Home />} />
              </Route>
            </Routes>
            <CookieConsent />
            <Footer />
          </Suspense>
        </div>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
